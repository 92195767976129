import { graphql, Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import iconAdv1 from "../images/FeaturesOverview/icon-adv-1.png";
import iconAdv2 from "../images/FeaturesOverview/icon-adv-2.png";
import iconAdv3 from "../images/FeaturesOverview/icon-adv-3.png";
import ContactForm from "../components/contactForm/form";
import Faq from "../components/faq/faq";
import Features from "../components/features/features";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import ImageText from "../components/imageText/imageText";
import News from "../components/news/news";
import Seo from "../components/seo";
import Title from "../components/title/title";
import Top from "../components/top/top";

// markup
const FeaturesOverviewPage = ({ location, data }) => {
  const formRef = React.useRef(null);
  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const topProps = {
    layout: 2,
    imageSize: "big",
    scrollToForm: scrollToForm,
    title: (
      <>
        Streamline Your
        <br /> Online Orders
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        // alt="online ordering"
        // src="../images/FeaturesOverview/online-orders.png"
        alt="Q-commerce"
        src="../images/FeaturesOverview/Q-commerce.png"
        className="mw-100"
      />
    ),
  };

  const imageTextProps1 = {
    layout: "equal-big",
    title: "Menu Management",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <>
        <p className="mb-2 mb-lg-3">
          Orders.co Master Menu Management system give you complete control over
          all your menus in one user-friendly place. Menu Sync ™ allows
          restaurants to link all their menus to an Orders.co Master Menu and
          have uniformity across all connected platforms. Saving precious time
          and money.
        </p>
        <Link to="/menu-management/" className="orange-text d-inline-block">
          Learn more
        </Link>
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="menu management"
        src="../images/FeaturesOverview/menu-management.png"
      />
    ),
  };

  const imageTextProps2 = {
    layout: "equal-big",
    title: "Orders Consolidation",
    titleStyle: "smaller",
    imagePosition: "right",
    description: (
      <>
        <p className="mb-2 mb-lg-3">
          Orders.co consolidation integrates all your live delivery apps into
          one simple dashboard and device. Gone are the days of staff bouncing
          between multiple tablets to fulfill orders. Increase staff
          productivity and take back your counter space!
        </p>
        <Link
          to="/orders-consolidation/"
          className="orange-text d-inline-block"
        >
          Learn more
        </Link>
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="orders consolidation"
        src="../images/FeaturesOverview/orders-consolidation.png"
      />
    ),
  };

  const imageTextProps3 = {
    layout: "equal-big",
    title: "Custom Ordering Website",
    titleStyle: "smaller",
    imagePosition: "left",
    description: (
      <>
        <p className="mb-2 mb-lg-3">
          Setup your very own commission-free ordering website in minutes! With
          the help of our expert support team, build the perfect Master Menu and
          Orders.co will generate a personalized website in no time! Give your
          customers a direct-ordering option with loyalty rewards to increase
          repeat business. Cut out the middleman today!
        </p>
        <Link
          to="/custom-ordering-website/"
          className="orange-text d-inline-block"
        >
          Learn more
        </Link>
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="ordering webist restaurant"
        src="../images/FeaturesOverview/restaurant-owner.png"
      />
    ),
  };

  const imageTextProps4 = {
    layout: "equal-big",
    title: "Reporting",
    titleStyle: "smaller",
    imagePosition: "right",
    description: (
      <>
        <p className="mb-2 mb-lg-3">
          Orders.co provides a full reporting suite to all restaurants
          regardless of size. Review sales statistics, top selling items and
          even hourly reports. All from one user-friendly dashboard designed to
          help make tough decisions trivial.
        </p>
        <Link to="/reporting/" className="orange-text d-inline-block">
          Learn more
        </Link>
      </>
    ),
    image: (
      <StaticImage
        placeholder="none"
        loading="eager"
        alt="user-friendly dashboard "
        src="../images/FeaturesOverview/reporting.png"
      />
    ),
  };

  const features = [
    {
      bg: "#F1F7FF",
      icon: iconAdv1,
      title: "Direct Ordering",
      description:
        "Cut out the middlemen and give your customers an easy way to order directly from you without the commission!",
    },
    {
      bg: "#DEFFEE",
      icon: iconAdv2,
      title: "Analyze Data Easily",
      description:
        "Quickly generate various reports and review only what you need with preset and customizable data filters.",
    },
    {
      bg: "#FFF7E3",
      icon: iconAdv3,
      title: "One Device For All Orders",
      description:
        "Take back your counter space and save employee time by receiving all your orders into one device!",
    },
  ];

  const questions = [
    {
      question: `How long does it take to set up Orders.co?`,
      answer: `Orders.co’s integration and setup takes around 1-2 weeks, depending on the current workload. For a more detailed estimate, contact a sales representative.`,
    },
    {
      question: `How much does Orders.co setup cost?`,
      answer: `Orders.co setup fee is $100.00`,
    },
    {
      question: `Which delivery platforms are supported?`,
      answer: `Currently Orders.co supports Grubhub, Postmates, UberEats, and DoorDash. With many more on the way. Restaurant partners are also encouraged to request new platform integrations.`,
    },
    {
      question: `How does my menu become a website?`,
      answer: `Once your menu has been created in the Orders.co Master Menu it is linked to your website and can be live in moments.`,
    },
    {
      question: `How does Orders.co work with third party apps?`,
      answer: `Orders.co communicates with delivery apps in multiple ways. We receive order information while sending confirmation & cancellation info, we import and export menus, as well as sales/reports.`,
    },
    {
      question: `Do you provide the tablets to the restaurants you work with?`,
      answer: `Orders.co provides our All-In-One device; equipped with an integrated tablet and ticket printer.`,
    },
  ];

  return (
    <>
      <Seo
        title={"Features Overview"}
        description={""}
        pageSeo={data.wpPage}
        location={location}
      />
      <Header scrollToForm={scrollToForm}></Header>
      <main>
        <Top {...topProps} />
        <Title>
          <b style={{ fontSize: "45px", lineHeight: "77px", opacity: "1" }}>
            Restaurant Management From One Device
          </b>
        </Title>
        <ImageText {...imageTextProps1} />
        <ImageText {...imageTextProps2} />
        <ImageText {...imageTextProps3} />
        <ImageText {...imageTextProps4} />
        <Features
          smallTitle={true}
          items={features}
          title={
            <span className="text-xl-center d-block">
              More Time for Your Customers and Higher Profits
            </span>
          }
        />
        <News items={data.allWpPost} />
        <Faq items={questions} />
        <div ref={formRef}>
          <ContactForm
            titleStyle="small"
            title={
              <>
                Manage and Optimize
                <br /> Delivery Across All
                <br /> Locations
              </>
            }
          ></ContactForm>
        </div>
      </main>
      <Footer scrollToForm={scrollToForm}></Footer>
    </>
  );
};

export const query = graphql`
  query {
    wpPage(slug: { eq: "features-overview" }) {
      title
      id
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    allWpPost(
      sort: {fields: [date], order: DESC}
      limit: 3
      skip: 0
      filter: {categories: {nodes: {elemMatch: {slug: {eq: "features-overview"}}}}}
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            gatsbyImage(width: 600,quality: 80)
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: TRACED_SVG, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;

export default FeaturesOverviewPage;
